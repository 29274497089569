import { APIPartner, PartnerPatch } from "../../server/types/index.js";
import { ThunkAction } from "../types/index.js";
import { fetch } from "../utils/utils.js";
import { showAlert } from "./Alerts.js";

export type Action =
  | { type: "GET_PARTNERS_SUCCESS"; partners: APIPartner[] }
  | { type: "GET_PARTNERS_START" }
  | { type: "GET_PARTNERS_ERROR"; error: Error }
  | { type: "PATCH_PARTNER_SUCCESS"; partner: APIPartner }
  | { type: "PATCH_PARTNER_ERROR"; error: Error };

export const getPartners = (siteId: string): ThunkAction<Promise<void>> => {
  return (dispatch, getState) => {
    if (getState().loadStates.partners !== "unloaded") {
      return Promise.resolve();
    }

    dispatch(getPartnersStart());
    return fetch({ dispatch })
      .get(`sites/${siteId}/partners`)
      .then((res) => {
        dispatch(getPartnersSuccess(res.data));
      })
      .catch((error) => {
        dispatch(getPartnersError(error));
        throw error;
      });
  };
};

export const getPartnersSuccess = (partners: APIPartner[]): Action => ({
  type: "GET_PARTNERS_SUCCESS",
  partners,
});

const getPartnersError = (error: Error): Action => ({
  type: "GET_PARTNERS_ERROR",
  error,
});

const getPartnersStart = (): Action => ({ type: "GET_PARTNERS_START" });

export const patchPartner = (
  siteId: string,
  partnerId: string,
  body: PartnerPatch,
): ThunkAction<void> => {
  return (dispatch) => {
    return fetch({ dispatch })
      .patch(`sites/${siteId}/partners/${partnerId}`, body)
      .then((res) => {
        dispatch(patchPartnerSuccess(res.data));
      })
      .catch((error) => {
        dispatch(
          showAlert(
            `Der Partner "${partnerId}" konnte nicht aktualisiert werden!`,
          ),
        );
        throw error;
      });
  };
};

const patchPartnerSuccess = (partner: APIPartner): Action => ({
  type: "PATCH_PARTNER_SUCCESS",
  partner,
});
