import { FunctionComponent, useEffect, useRef, useState } from "react";
import {
  Language,
  LinkData,
  PageSettingsOnSave,
  PageTranslation,
  PageTranslationPostRequest,
  PageTranslationType,
} from "../types/index.js";
import { customSlugify, keys } from "../utils/utils.js";
import Checkbox from "./Checkbox.js";
import FormError from "./FormError.js";
import FormInfo from "./FormInfo.js";
import FormTranslation from "./FormTranslation.js";
import LinkSelectForm from "./LinkSelectForm.js";
import ModalDialog from "./ModalDialog.js";
import PopUpsList from "./PopUpsList.js";

interface Props {
  translation?: PageTranslation;
  pageTranslationType: PageTranslationType;
  languageId: Language;
  initialFormValues: PageTranslationPostRequest;
  initialPopUpModuleId: string | null;
  hasGlobalPopUpModule: boolean;
  onSubmit: PageSettingsOnSave;
}

const checkLinkValidity = (
  link: LinkData | undefined,
  pageTranslationType: PageTranslationType,
) => {
  if (!link || pageTranslationType !== "redirect") return true;
  const hasNoLink = keys(link).every((key) => !link[key]);
  return !hasNoLink;
};

const PageSettingsForm: FunctionComponent<Props> = ({
  initialFormValues,
  translation,
  pageTranslationType,
  languageId,
  onSubmit,
  initialPopUpModuleId,
  hasGlobalPopUpModule,
}) => {
  const slugIsTouchedRef = useRef(false);
  const [popUpModuleId, setPopUpModuleIdState] = useState(initialPopUpModuleId);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [modalActivePopUpId, setModalActivePopUpId] = useState<string>();

  const [form, setForm] =
    useState<PageTranslationPostRequest>(initialFormValues);

  // Update form when switching language
  useEffect(() => {
    setForm(initialFormValues);
  }, [languageId]);

  const setPopUpModuleId = (activeId: string | null) =>
    setPopUpModuleIdState((currentActiveId) =>
      currentActiveId === activeId ? null : activeId,
    );

  const linkValidity = checkLinkValidity(
    form.link || undefined,
    pageTranslationType,
  );

  const setFormFields = (fields: Partial<PageTranslationPostRequest>) =>
    setForm((state) => ({ ...state, ...fields }));

  return (
    <form
      className="Form"
      onSubmit={(e) => {
        e.preventDefault();
        setSubmitClicked(true);
        // Don’t submit if the link is invalid
        if (!linkValidity) return;

        onSubmit({
          translation: {
            languageId,
            title: form.title,
            description: form.description,
            documentTitle: form.documentTitle,
            isVisible: form.isVisible,
            link: form.link,
            slug: form.slug,
          },
          popUpModuleId,
        });
      }}
    >
      <div className="Form__Field">
        <input
          type="text"
          value={form.title}
          name="title"
          required
          onBlur={(e) => {
            e.target.reportValidity();
          }}
          onChange={(e) => {
            setFormFields({ title: e.target.value });
            !initialFormValues.slug &&
              !slugIsTouchedRef.current &&
              setFormFields({ slug: customSlugify(e.target.value) });
          }}
          placeholder="Leer"
        />
        <FormTranslation translation={translation?.title} />
        <div className="Form__Label">
          <label>Navigationstitel</label>
        </div>
      </div>
      <div className="Form__Field">
        <input
          type="text"
          required
          value={form.slug ?? ""}
          name="slug"
          onBlur={(e) => {
            e.target.reportValidity();
          }}
          onChange={(e) => {
            slugIsTouchedRef.current = true;
            setFormFields({ slug: e.target.value });
          }}
          placeholder="Leer"
        />
        <FormTranslation translation={translation?.slug} />
        <div className="Form__Label">
          <label>Pfad</label>
        </div>
      </div>
      {pageTranslationType === "redirect" && (
        <>
          <LinkSelectForm
            languageId={languageId}
            link={form.link}
            onLinkChange={(link) => setFormFields({ link })}
            showActionButtons={false}
            showLanguageSelection={false}
            allowedLinkTypes={{
              external: true,
              modules: true,
              pages: true,
              folders: true,
            }}
          >
            <FormError
              error={
                !linkValidity && submitClicked
                  ? "Der Link darf nicht leer sein."
                  : undefined
              }
            />
          </LinkSelectForm>
        </>
      )}

      <Checkbox
        checkedStatus={!!form.isVisible}
        htmlId="page-translation-visible"
        text="Seite im Hauptmenü anzeigen"
        onChange={(isVisible) => setFormFields({ isVisible })}
      />

      {pageTranslationType === "page" && (
        <fieldset className="Form__Fieldset">
          <legend>SEO Einstellungen</legend>
          <div className="Form__Field">
            <input
              type="text"
              value={form.documentTitle || ""}
              name="documentTitle"
              onChange={(e) => setFormFields({ documentTitle: e.target.value })}
              placeholder="Leer"
            />
            <FormTranslation translation={translation?.documentTitle} />
            <div className="Form__Label">
              <label>Seitentitel</label>
            </div>
          </div>
          <div className="Form__Field">
            <textarea
              value={form.description || ""}
              name="description"
              onChange={(e) => setFormFields({ description: e.target.value })}
              placeholder="Leer"
            />
            <FormTranslation translation={translation?.description} />
            <FormInfo>
              Wird in Suchmaschinen in der Ergebnisliste angezeigt.
            </FormInfo>
            <div className="Form__Label">
              <label>Beschreibung</label>
            </div>
          </div>

          <PopUpsList
            activeId={popUpModuleId}
            label="Pop-up aktivieren"
            languageId={languageId}
            onSetActive={(activeId) =>
              hasGlobalPopUpModule && activeId !== popUpModuleId
                ? setModalActivePopUpId(activeId)
                : setPopUpModuleId(activeId)
            }
          />
        </fieldset>
      )}

      <button type="submit" className="Btn Btn--action">
        Speichern
      </button>

      <ModalDialog
        isOpen={modalActivePopUpId !== undefined}
        title="Achtung"
        onClose={(status) => {
          status === "confirmed" &&
            modalActivePopUpId !== undefined &&
            setPopUpModuleId(modalActivePopUpId);

          setModalActivePopUpId(undefined);
        }}
      >
        Wollen Sie für diese Seite das globale Pop-Up überschreiben?
      </ModalDialog>
    </form>
  );
};

export default PageSettingsForm;
