import { FunctionComponent, useState } from "react";
import { type Revision } from "../types/index.js";
import { formatDateTime } from "../utils/utils.js";
import Icon from "./Icon.js";
import ModalDialog from "./ModalDialog.js";

interface Props {
  revision: Revision;
  maxFavoritesReached: boolean;
  onRevert: (id: string) => void;
  onToggleFavoriteStatus: (id: string, isFavorite: boolean) => void;
}

const Revision: FunctionComponent<Props> = ({
  revision: { id, publishedAt, isFavorite },
  maxFavoritesReached,
  onRevert,
  onToggleFavoriteStatus,
}) => {
  const [isModalOpen, showModal] = useState(false);
  const allowSetFavorite = isFavorite || !(isFavorite || maxFavoritesReached);

  return (
    <li className="RevisionsList__Item">
      <div className="RevisionsList__Caption">
        {formatDateTime(publishedAt)}
      </div>
      <div className="BtnGroup">
        <button
          title="auf diese Version zurücksetzen"
          className="Btn"
          onClick={() => {
            showModal(true);
          }}
        >
          <Icon glyph="history" />
        </button>
        <button
          title={
            isFavorite
              ? "von den Favoriten entfernen"
              : maxFavoritesReached
                ? "maximale Anzahl der Favoriten erreicht"
                : "zu Favoriten hinzufügen"
          }
          disabled={!allowSetFavorite}
          className="Btn"
          onClick={() =>
            allowSetFavorite
              ? onToggleFavoriteStatus(id, !isFavorite)
              : undefined
          }
        >
          <Icon glyph={isFavorite ? "favorite" : "favorite-border"} />
        </button>
      </div>
      <ModalDialog
        type="confirm"
        title="Versionsverlauf"
        isOpen={isModalOpen}
        onClose={(status) => {
          showModal(false);
          status === "confirmed" && onRevert(id);
        }}
      >
        Sind Sie sicher, dass Sie auf die veröffentlichte Version vom{" "}
        {formatDateTime(publishedAt)} zurückspringen möchten? Hinweis: Alle
        unveröffentlichten Änderungen gehen mit diesem Schritt verloren.
      </ModalDialog>
    </li>
  );
};

export default Revision;
