import {
  DndContext,
  Modifier,
  PointerSensor,
  pointerWithin,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis, snapCenterToCursor } from "@dnd-kit/modifiers";
import { FunctionComponent, ReactNode } from "react";
import { ConnectedProps, connect } from "react-redux";
import { dragModule, postMoveModule } from "../actions/Modules.js";
import {
  DragDropType,
  ModuleSection,
  OnModuleAdd,
  TranslatedPage,
} from "../types/index.js";
import { checkIsDragDropData, getShortId } from "../utils/utils.js";

type ReduxProps = ConnectedProps<typeof connector>;

interface Props {
  page: TranslatedPage;
  children: ReactNode;
  moduleSections: ModuleSection[];
  onModuleAdd: OnModuleAdd;
}

const PageDnD: FunctionComponent<Props & ReduxProps> = ({
  page,
  children,
  postMoveModule,
  dragModule,
  moduleSections,
  onModuleAdd,
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // Set a distance threshold to allow clicking on elements.
        distance: 8,
      },
    }),
  );

  const handleModuleEndDrag = ({
    oldIndex,
    newIndex,
    id,
  }: {
    oldIndex: number;
    newIndex: number;
    id: string;
  }) => {
    dragModule(page.id, oldIndex, newIndex);
    postMoveModule({
      moduleId: id,
      pageId: page.id,
      siteId: page.siteId,
      hasMovedBy: newIndex - oldIndex,
    });
  };

  return (
    <DndContext
      modifiers={[dynamicSnapCenterToCursor, dynamicRestrictToVerticalAxis]}
      sensors={sensors}
      collisionDetection={pointerWithin}
      onDragEnd={({ active, over }) => {
        if (!over || active.id === over.id) return;
        const activeData = active.data;
        const overData = over.data;

        if (
          checkIsDragDropData(activeData, DragDropType.NewModule) &&
          (checkIsDragDropData(overData, DragDropType.ModuleDropSpace) ||
            checkIsDragDropData(overData, DragDropType.EmptyModules))
        ) {
          const index = checkIsDragDropData(
            overData,
            DragDropType.ModuleDropSpace,
          )
            ? overData.current.index - 1
            : undefined;

          onModuleAdd({
            moduleId: getShortId(),
            moduleType: activeData.current.moduleType,
            usePageId: true,
            index,
          });
          return;
        }

        const activeId = String(active.id);
        const modules = getSectionModules(moduleSections, String(activeId));
        if (!modules) return;

        const oldIndex = modules.findIndex(({ id }) => id === active.id);
        const newIndex = modules.findIndex(({ id }) => id === over.id);

        handleModuleEndDrag({ id: activeId, oldIndex, newIndex });
      }}
    >
      {children}
    </DndContext>
  );
};

const getSectionModules = (moduleSections: ModuleSection[], activeId: string) =>
  moduleSections
    .filter(({ isSortable }) => isSortable)
    .find(({ items }) => items.some(({ id }) => id === activeId))?.items;

const dynamicSnapCenterToCursor: Modifier = (params) =>
  params.active &&
  checkIsDragDropData(params.active.data, DragDropType.NewModule)
    ? snapCenterToCursor(params)
    : params.transform;

const dynamicRestrictToVerticalAxis: Modifier = (params) =>
  params.active && checkIsDragDropData(params.active.data, DragDropType.Module)
    ? restrictToVerticalAxis(params)
    : params.transform;

const mapDispatchToProps = { dragModule, postMoveModule };

const connector = connect(undefined, mapDispatchToProps);

export default connector(PageDnD);
