import { ThunkAction } from "../types/index.js";
import { fetch } from "../utils/utils.js";
import { showAlert } from "./Alerts.js";

export const downloadNewsletterCSV =
  (siteId: string): ThunkAction<Promise<void>> =>
  async (dispatch) => {
    try {
      const { data } = await fetch({ dispatch }).get(
        `sites/${siteId}/newsletter`,
        { responseType: "blob" },
      );
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "newsletter.csv");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      dispatch(
        showAlert(
          "Die CSV-Datei mit den Newsletter-Anmeldungen konnte nicht heruntergeladen werden!",
        ),
      );
      throw error;
    }
  };

export const deleteNewsletterData =
  (siteId: string): ThunkAction<Promise<void>> =>
  async (dispatch) => {
    try {
      await fetch({ dispatch }).delete(`sites/${siteId}/newsletter`);
    } catch (error) {
      dispatch(
        showAlert("Die Newsletter-Daten konnten nicht gelöscht werden!"),
      );
      throw error;
    }
  };
