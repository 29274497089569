import { FunctionComponent, useState } from "react";
import { ConnectedProps, MapStateToProps, connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import {
  getSelectedLogoIds,
  getTranslatedPartnerLogos,
  getTranslatedPartners,
} from "../selectors/partnerLogos.js";
import {
  Language,
  LogoCategory,
  StoreState,
  TranslatedPartner,
  TranslatedPartnerLogo,
} from "../types/index.js";
import { getURL } from "../utils/utils.js";
import FormInfo from "./FormInfo.js";
import MediaLibraryImage from "./MediaLibraryImage.js";
import Sidebar from "./Sidebar.js";

interface SelectedLogos {
  [partnerId: string]: TranslatedPartnerLogo;
}

type Props = RouteComponentProps<{
  siteId: string;
  pageId: string;
  languageId: Language;
  moduleId: string;
  logoCategoryId: string;
}>;

interface StateProps {
  partners: TranslatedPartner[];
  selectedLogos: SelectedLogos;
}

type ReduxProps = ConnectedProps<typeof connector>;

const PartnerLibrary: FunctionComponent<Props & ReduxProps> = ({
  partners,
  selectedLogos,
  match,
  match: {
    params: { siteId, pageId, languageId, moduleId },
  },
  history,
}) => {
  const [filterText, setFilterText] = useState("");

  const filterPartner = ({ translation: { name } }: TranslatedPartner) => {
    if (!filterText) return true;
    if (!name) return false;
    return name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1;
  };

  const filteredPartners = partners.filter(filterPartner);

  return (
    <Sidebar
      className="MediaLibrary"
      heading="Partnerbibliothek"
      closeLink={getURL(
        siteId,
        "pages",
        pageId,
        languageId,
        "modules",
        moduleId,
      )}
    >
      <form className="Form" onSubmit={(e) => e.preventDefault()}>
        <div className="Form__Field">
          <input
            type="search"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
          <div className="Form__Label">
            <label>Suche</label>
          </div>
          {!filteredPartners.length && (
            <FormInfo>Keine Partner gefunden!</FormInfo>
          )}
        </div>
      </form>
      <div className="MediaLibrary__Images">
        {filteredPartners.map(({ id, translation: { name }, logos }) => {
          const logo = selectedLogos[id] || logos[0];
          if (!logo) return null;

          return (
            <MediaLibraryImage
              key={id}
              url={logo.translation.url}
              title={name}
              category="logo"
              background={logo.background}
              isSelected={!!selectedLogos[id]}
              imageCount={logos.length}
              onSelection={() => {
                history.push(getURL(match.url, id));
              }}
            />
          );
        })}
      </div>
    </Sidebar>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  state,
  {
    match: {
      params: { languageId, moduleId, logoCategoryId },
    },
  },
): StateProps => {
  const logoCategory = +logoCategoryId as LogoCategory;
  const logoIds = getSelectedLogoIds(state, { moduleId, logoCategory });

  const selectedLogos = getTranslatedPartnerLogos(state, {
    logoIds,
    languageId,
  }).reduce<SelectedLogos>(
    (logoMap, logo) => ({ ...logoMap, [logo.partner.id]: logo }),
    {},
  );

  return {
    partners: getTranslatedPartners(state, { languageId }),
    selectedLogos,
  };
};

const connector = connect(mapStateToProps);

export default connector(PartnerLibrary);
