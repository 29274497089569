import { FunctionComponent, useEffect } from "react";
import { ConnectedProps, MapStateToProps, connect } from "react-redux";
import { getAllMediaGroups, getMediaGroup } from "../actions/MediaLibrary.js";
import { getPicturesById } from "../selectors/pictures.js";
import { getActiveSite } from "../selectors/sites.js";
import {
  ImageModuleIdsByPictureId,
  Language,
  Picture,
  PictureGroup,
  Pictures,
  PicturesByGroupId,
  PicturesByGroupIdLoadStates,
  StoreState,
} from "../types/index.js";
import FormInfo from "./FormInfo.js";
import PictureGroupItem from "./PictureGroupItem.js";
import Spinner from "./Spinner.js";

interface Props {
  onSelection: (pictureId: string) => void;
  selectedImages: ImageModuleIdsByPictureId;
  languageId: Language;
}

interface FixedPictureGroup {
  name: string;
  pictures: Picture[];
}

interface StateProps {
  siteId: string;
  isLoaded: boolean;
  groups: PictureGroup[];
  picturesByGroupId: PicturesByGroupId;
  pictures: Pictures;
  picturesByGroupIdLoadStates: PicturesByGroupIdLoadStates;
  fixedPictureGroups: FixedPictureGroup[];
}

type ReduxProps = ConnectedProps<typeof connector>;

const PictureGroups: FunctionComponent<Props & ReduxProps> = ({
  getAllMediaGroups,
  getMediaGroup,
  siteId,
  isLoaded,
  groups,
  picturesByGroupId,
  picturesByGroupIdLoadStates,
  pictures,
  onSelection,
  selectedImages,
  fixedPictureGroups,
}) => {
  const hasGroups = !!(groups.length || fixedPictureGroups.length);

  useEffect(() => {
    getAllMediaGroups(siteId);
  }, []);

  return (
    <div className="MediaLibrary__PictureGroups">
      {!isLoaded && (
        <div className="MediaLibrary__PictureGroupsLoading">
          <Spinner />
        </div>
      )}

      {isLoaded && !hasGroups && (
        <FormInfo>
          Es sind keine Bildergruppen im Easy Channel vorhanden.
        </FormInfo>
      )}

      {isLoaded && hasGroups && (
        <ul className="MediaLibrary__PictureGroupsList List">
          {fixedPictureGroups.map(({ name, pictures }) => (
            <PictureGroupItem
              key={name}
              groupName={name}
              pictures={pictures}
              groupIsLoaded={true}
              onSelection={onSelection}
              selectedImages={selectedImages}
            />
          ))}

          {groups.map(({ id: groupId, name }) => {
            const groupIsLoaded =
              picturesByGroupIdLoadStates[groupId] === "loaded";
            const picturesOfGroup = (picturesByGroupId[groupId] ?? [])
              .map((pictureId) => pictures[pictureId])
              .filter(Boolean);

            return (
              <PictureGroupItem
                key={groupId}
                groupIsLoaded={groupIsLoaded}
                onExpand={() => getMediaGroup(siteId, groupId)}
                onSelection={onSelection}
                pictures={picturesOfGroup}
                selectedImages={selectedImages}
                groupName={name}
              />
            );
          })}
        </ul>
      )}
    </div>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  {
    sites,
    accommodation,
    mediaLibrary: { groups, picturesByGroupId, pictures, logoIds },
    loadStates: {
      mediaLibrary: {
        groups: groupsLoadStatus,
        pictures: picturesLoadStatus,
        picturesByGroupId: picturesByGroupIdLoadStates,
      },
    },
  },
  { languageId },
): StateProps => {
  const site = getActiveSite(sites);
  const fixedPictureGroups: FixedPictureGroup[] = [
    { name: "Logo", pictures: getPicturesById(pictures, logoIds) },
    {
      name: "Betriebsbilder",
      pictures: accommodation[languageId]?.pictures ?? [],
    },
    {
      name: "Bildergalerie",
      pictures: accommodation[languageId]?.gallery ?? [],
    },
  ].filter(({ pictures }) => pictures.length);

  return {
    siteId: site.id,
    isLoaded: groupsLoadStatus === "loaded" && picturesLoadStatus === "loaded",
    groups,
    picturesByGroupId,
    pictures,
    picturesByGroupIdLoadStates,
    fixedPictureGroups,
  };
};

const mapDispatchToProps = { getAllMediaGroups, getMediaGroup };

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(PictureGroups);
