import ClassNames from "classnames";
import { FunctionComponent, useState } from "react";
import { ConnectedProps, MapStateToProps, connect } from "react-redux";
import { showAlert } from "../actions/Alerts.js";
import { deleteMedia, uploadMedia } from "../actions/MediaLibrary.js";
import { setModuleSetting } from "../actions/Modules.js";
import { getActiveSite } from "../selectors/sites.js";
import {
  HeaderModuleSettings,
  Language,
  Module,
  Picture,
  StoreState,
  ThunkDispatch,
} from "../types/index.js";
import FileUpload from "./FileUpload.js";
import FormInfo from "./FormInfo.js";
import Icon from "./Icon.js";
import ModalDialog from "./ModalDialog.js";

interface Props {
  languageId: Language;
}

interface StateProps {
  logos: Picture[];
  activeLogoId: string | undefined;
  headerModuleId: string | undefined;
  siteId: string;
  isAdminUser: boolean;
}

type ReduxProps = ConnectedProps<typeof connector>;

const AccommodationLogos: FunctionComponent<Props & ReduxProps> = ({
  logos,
  activeLogoId,
  setActiveLogoId,
  uploadMedia,
  showAlert,
  headerModuleId,
  siteId,
  deleteMedia,
  isAdminUser,
}) => {
  const [idToDelete, setIdToDelete] = useState<string>();

  return (
    <div className="Form">
      {isAdminUser && (
        <FileUpload
          acceptFileExtensions={["svg"]}
          htmlId="accommodation-logo-upload"
          label="Betriebslogo hochladen"
          onUpload={async (file) => {
            await uploadMedia(siteId, file);
            showAlert("Logo erfolgreich hochgeladen", "success");
          }}
          // 100 KiB
          maxSize={100 * 1024}
          fileUploadMessage="Logo hierher ziehen oder"
        >
          <FormInfo>
            Es können nur SVG-Dateien mit einer Dateigröße von maximal 100 KiB
            hochgeladen werden. Stellen Sie zudem sicher, dass diese einen
            transparenten Hintergrund haben, keine breiten Ränder aufweisen und
            keine eingebetteten Rastergrafiken enthalten.
          </FormInfo>
        </FileUpload>
      )}

      {Boolean(logos.length) && (
        <div className="Form__Field">
          <div className="Form__Content Form__Content--wrap">
            <ul className="ControlItemsList">
              {logos.map(({ url, id, title }) => (
                <li key={id} className="ControlItem">
                  <div className="ControlItem__Inner">
                    <div className="ControlItem__Preview">
                      <div className="ControlItem__PreviewInner">
                        <div className="MediaLibrary__Image MediaLibrary__Image--dark MediaLibrary__Image--narrow">
                          <img
                            loading="lazy"
                            className="FullImage"
                            src={url}
                            alt={title ?? ""}
                          />
                        </div>
                      </div>
                    </div>

                    {isAdminUser && (
                      <button
                        className="Btn Btn--compact ControlItem__Btn"
                        type="button"
                        title="Löschen"
                        onClick={() => setIdToDelete(id)}
                      >
                        <Icon glyph="delete" />
                      </button>
                    )}

                    <button
                      className={ClassNames(
                        "Btn",
                        "Btn--compact",
                        "ControlItem__Btn",
                        { "Btn--active": activeLogoId === id },
                      )}
                      type="button"
                      title="Auswählen"
                      onClick={() =>
                        headerModuleId &&
                        setActiveLogoId({ headerModuleId, logoId: id, siteId })
                      }
                    >
                      <Icon glyph="check" />
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="Form__Label">
            <label>Auswahl</label>
          </div>
        </div>
      )}

      <ModalDialog
        title="Logo löschen"
        type="confirm"
        onClose={(status) => {
          status === "confirmed" &&
            idToDelete !== undefined &&
            deleteMedia(siteId, idToDelete);
          setIdToDelete(undefined);
        }}
        isOpen={idToDelete !== undefined}
      >
        Wollen Sie dieses Logo wirklich löschen?
      </ModalDialog>
    </div>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = ({
  mediaLibrary: { logoIds, pictures },
  modules,
  sites,
  user,
}): StateProps => {
  const site = getActiveSite(sites);
  // Only show SVG logos (hide logos coming from EasyChannel)
  const logos = logoIds
    .map((logoId) => pictures[logoId])
    .filter(({ url }) => url.includes(".svg"));
  const headerModuleId = modules.bySiteModuleType.HeaderModule?.[0];
  const headerModule = (
    headerModuleId ? modules.byId[headerModuleId] : undefined
  ) as Module<HeaderModuleSettings> | undefined;
  const activeLogoId = headerModule?.settings.logoId;

  return {
    logos,
    activeLogoId,
    headerModuleId,
    siteId: site.id,
    isAdminUser: user.isAdmin ?? false,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  { languageId }: Props,
) => ({
  uploadMedia: (...params: Parameters<typeof uploadMedia>) =>
    dispatch(uploadMedia(...params)),
  showAlert: (...params: Parameters<typeof showAlert>) =>
    dispatch(showAlert(...params)),
  deleteMedia: (...params: Parameters<typeof deleteMedia>) =>
    dispatch(deleteMedia(...params)),
  setActiveLogoId: ({
    logoId,
    siteId,
    headerModuleId,
  }: {
    logoId: string | undefined;
    siteId: string;
    headerModuleId: string;
  }) =>
    dispatch(
      setModuleSetting<HeaderModuleSettings>(
        { id: headerModuleId, siteId, translation: { languageId } },
        { global: { logoId } },
      ),
    ),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(AccommodationLogos);
