import { DragOverlay, useDndMonitor } from "@dnd-kit/core";
import { FunctionComponent, useState } from "react";
import { DragDropType, ModuleType, OnModuleAdd } from "../types/index.js";
import { checkIsDragDropData, getModuleIcon } from "../utils/utils.js";
import DraggableMockModule from "./DraggableMockModule.js";
import FormInfo from "./FormInfo.js";
import GlobalModulesList from "./GlobalModulesList.js";
import MockModule from "./MockModule.js";
import MockModuleOverlay from "./MockModuleOverlay.js";
import Sidebar from "./Sidebar.js";

interface Props {
  moduleCount: number;
  onAdd: OnModuleAdd;
  isPage: boolean;
  isAdminUser: boolean;
}

interface Route {
  heading: string;
  modules: ModuleType[];
}

interface ModuleRouting {
  main: Route;
  external: Route;
}

type Routes = keyof ModuleRouting;

const adminOnlyModules: ModuleType[] = ["HTMLModule"];

const routes: ModuleRouting = {
  main: {
    heading: "Seitenmodule",
    modules: [
      "ImagesModule",
      "ImageGalleryModule",
      "TextModule",
      "SeparatorModule",
      "RoomsModule",
      "PriceModule",
      "BookingModule",
      "PortalModule",
      "EnquiryModule",
      "SpecialsModule",
      "HighlightsModule",
      "MapModule",
      "VideoModule",
      "WeatherWebcamModule",
      "NewsletterModule",
      "QuestionsAndAnswersModule",
      "QuoteModule",
      "AccommodationFeaturesModule",
    ],
  },
  external: {
    heading: "Externe Anbieter",
    modules: [
      "EasiPayModule",
      "EasiFastCheckInModule",
      "MTSToursModule",
      "GastroPoolInsuranceModule",
      "Google360VirtualTourModule",
      "PeerTvModule",
      "ACSmartVoucherModule",
      "GuestnetWidgetModule",
      "HogastGastropoolSmartPayModule",
      "HogastGastropoolPayModule",
      "ArriveInSouthTyrolModule",
      "GetavoModule",
      "HTMLModule",
    ],
  },
};

const ModuleList: FunctionComponent<Props> = ({
  onAdd,
  isPage,
  isAdminUser,
}) => {
  const [currentRoute, navigateTo] = useState<Routes>("main");
  const [activeId, setActiveId] = useState<ModuleType>();

  useDndMonitor({
    onDragStart: ({ active }) =>
      checkIsDragDropData(active.data, DragDropType.NewModule) &&
      setActiveId(active.data.current?.moduleType),
    onDragEnd: () => setActiveId(undefined),
    onDragCancel: () => setActiveId(undefined),
  });

  const route = routes[currentRoute];
  const isMain = currentRoute === "main";

  return (
    <Sidebar
      heading={route.heading}
      onClose={!isMain ? () => navigateTo("main") : undefined}
    >
      {!isPage && <FormInfo>Module sind für Seitenlinks deaktiviert.</FormInfo>}
      {isPage && (
        <div className="ModuleList">
          {route.modules
            .filter(
              (moduleType) =>
                // Remove admin-only-modules for non-admin users
                isAdminUser || adminOnlyModules.indexOf(moduleType) === -1,
            )
            .map((moduleType) => (
              <DraggableMockModule
                key={moduleType}
                moduleType={moduleType}
                onAdd={onAdd}
              />
            ))}

          {activeId && (
            <DragOverlay dropAnimation={null}>
              <MockModuleOverlay icon={getModuleIcon(activeId)} />
            </DragOverlay>
          )}
          {isMain && (
            <MockModule
              icon="extension"
              text="Externe Anbieter"
              onClick={() => navigateTo("external")}
              isSelected={false}
            />
          )}
        </div>
      )}
      {isPage && isMain && <GlobalModulesList onAdd={onAdd} />}
    </Sidebar>
  );
};

export default ModuleList;
