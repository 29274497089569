import { FunctionComponent } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { PartnerPatch } from "../../server/types/index.js";
import { changeLogo, upsertLogoStart } from "../actions/Logos.js";
import { patchPartner } from "../actions/Partners.js";
import {
  getSelectedLogoIds,
  getTranslatedPartner,
} from "../selectors/partnerLogos.js";
import { getActiveSite } from "../selectors/sites.js";
import {
  Language,
  LogoCategory,
  PartnerLinks,
  StoreState,
  TranslatedPartner,
} from "../types/index.js";
import { getTranslations, getURL, keys } from "../utils/utils.js";
import PartnerLogoSelectionForm from "./PartnerLogoSelectionForm.js";
import Sidebar from "./Sidebar.js";

type RouterProps = RouteComponentProps<{
  siteId: string;
  pageId: string;
  languageId: Language;
  moduleId: string;
  logoCategoryId: string;
  partnerId: string;
}>;

interface StateProps {
  translatedPartner?: TranslatedPartner;
  selectedLogoId: string | undefined;
  selectedLogoIds: string[];
  partnerLinks: PartnerLinks;
  logoCategory: LogoCategory;
}

type ReduxProps = ConnectedProps<typeof connector>;

const PartnerLogoSelection: FunctionComponent<RouterProps & ReduxProps> = ({
  translatedPartner,
  partnerLinks,
  selectedLogoId,
  logoCategory,
  patchPartner,
  changeLogo,
  history,
  match: {
    params: { siteId, pageId, languageId, moduleId },
  },
}) => {
  if (!translatedPartner) return null;
  const closeLink = getURL(
    siteId,
    "pages",
    pageId,
    languageId,
    "modules",
    moduleId,
  );

  return (
    <Sidebar
      className="PartnerLogoSelection"
      heading={translatedPartner.translation.name}
      closeLink={closeLink}
    >
      <PartnerLogoSelectionForm
        initialValues={{ selectedLogoId, partnerLinks }}
        logos={translatedPartner.logos}
        onSubmit={(form) => {
          const body = keys(form.partnerLinks).reduce<PartnerPatch>(
            (body, languageId) => {
              const current = form.partnerLinks[languageId];
              return {
                ...body,
                translations: {
                  ...body.translations,
                  [languageId]: { siteUrl: current ? current.url : null },
                },
              };
            },
            { translations: {} },
          );

          patchPartner(siteId, translatedPartner.id, body);
          form.selectedLogoId &&
            changeLogo(
              siteId,
              upsertLogoStart(
                moduleId,
                logoCategory,
                form.selectedLogoId,
                selectedLogoId,
              ),
            );
          history.push(closeLink);
        }}
      />
    </Sidebar>
  );
};

const getSelectedLogoId = (
  logoIds: string[],
  selectedLogoIds: string[],
): string | undefined => {
  const selectedLogoId = logoIds.find((logoId) => {
    return selectedLogoIds.indexOf(logoId) >= 0;
  });

  return selectedLogoId ? selectedLogoId : logoIds[0];
};

const mapStateToProps = (state: StoreState, props: RouterProps): StateProps => {
  const { partnerId, languageId, moduleId, logoCategoryId } =
    props.match.params;

  const { languages } = getActiveSite(state.sites);
  const logoCategory = +logoCategoryId as LogoCategory;

  const selectedLogoIds = getSelectedLogoIds(state, { moduleId, logoCategory });

  const translatedPartner = getTranslatedPartner(state, {
    partnerId,
    languageId,
    includeLogos: true,
  });

  const fullPartner = state.partners[partnerId];

  const selectedLogoId = fullPartner
    ? getSelectedLogoId(fullPartner.logos, selectedLogoIds)
    : undefined;

  const partnerLinks = languages.reduce<PartnerLinks>(
    (partnerLinks, language) => {
      if (!fullPartner) return partnerLinks;

      const translation = getTranslations(
        language.id,
        fullPartner.translations,
      );

      if (!translation) return partnerLinks;

      const { origin, url } = translation;

      const current: PartnerLinks[Language] = {
        hostname: new URL(origin).hostname.replace(/^www\./, ""),
        url,
      };

      return { ...partnerLinks, [language.id]: current };
    },
    {},
  );

  return {
    logoCategory,
    translatedPartner,
    partnerLinks,
    selectedLogoId,
    selectedLogoIds,
  };
};

const mapDispatchToProps = { patchPartner, changeLogo };

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(PartnerLogoSelection);
