import { useDraggable } from "@dnd-kit/core";
import { FunctionComponent } from "react";
import {
  DragDropType,
  DragDropTypes,
  ModuleType,
  OnModuleAdd,
} from "../types/index.js";
import {
  getModuleIcon,
  getModuleTypeTranslation,
  getShortId,
} from "../utils/utils.js";
import MockModule from "./MockModule.js";

interface Props {
  moduleType: ModuleType;
  onAdd: OnModuleAdd;
}

const DraggableMockModule: FunctionComponent<Props> = ({
  moduleType,
  onAdd,
}) => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: `new${moduleType}`,
    data: {
      type: DragDropType.NewModule,
      moduleType,
    } satisfies DragDropTypes[DragDropType.NewModule],
  });

  const icon = getModuleIcon(moduleType);
  const text = getModuleTypeTranslation(moduleType);

  return (
    <MockModule
      ref={setNodeRef}
      icon={icon}
      text={text}
      isSelected={false}
      onClick={() =>
        onAdd({ moduleType, moduleId: getShortId(), usePageId: true })
      }
      buttonProps={{ ...attributes, ...listeners }}
    />
  );
};

export default DraggableMockModule;
