import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import LoginPage from "./LoginPage.js";

const LoginImprint: FunctionComponent = () => {
  return (
    <LoginPage wrapperClassName="Login__Imprint">
      <Link to="/login">zurück zum Login</Link>
      <h1>Impressum</h1>
      <h2>CMS: technische Umsetzung, Konzept &amp; Design</h2>
      <address className="Login__Imprint__Address">
        HGV Service Genossenschaft
        <br />
        Schlachthofstraße 59
        <br />
        I-39100 Bozen
        <br />
        Tel. <a href="tel:00390471317840">+39 0471 317 840</a>
        <br />
        Fax +39 0471 317 701
        <br />
        <a href="mailto:info@bookingsuedtirol.com">info@bookingsuedtirol.com</a>
        <br />
        Mwst.-Nr.: 00576540215
      </address>
    </LoginPage>
  );
};

export default LoginImprint;
