import { FunctionComponent, useEffect } from "react";
import { ConnectedProps, MapStateToProps, connect } from "react-redux";
import { resetLinkSelect, setLinkSelect } from "../actions/LinkSelect.js";
import { getModules } from "../actions/Modules.js";
import { AllowedLinkTypes, LinkSelect, StoreState } from "../types/index.js";
import LinkSelectForm from "./LinkSelectForm.js";
import Sidebar from "./Sidebar.js";

interface Props {
  siteId: string;
  pageId: string;
  moduleId: string;
}

interface StateProps {
  linkSelect: LinkSelect;
  allowedLinkTypes: AllowedLinkTypes;
}

type ReduxProps = ConnectedProps<typeof connector>;

const ModuleSettingsLinkSelect: FunctionComponent<Props & ReduxProps> = ({
  setLinkSelect,
  resetLinkSelect,
  getModules,
  linkSelect: { id, linkData, confirmed },
  allowedLinkTypes,
  siteId,
  pageId: currentPageId,
  moduleId,
}) => {
  useEffect(() => {
    return () => {
      resetLinkSelect();
    };
  }, [moduleId]);

  if (!id || confirmed) return null;

  return (
    <Sidebar
      className="Sidebar--absolute"
      heading="Linkauswahl"
      onClose={resetLinkSelect}
    >
      <LinkSelectForm
        link={linkData}
        onSubmit={(linkData) => setLinkSelect(id, linkData, true)}
        showActionButtons={true}
        onPageSelect={(pageId) =>
          // `pageId !== currentPageId` makes sure to not force load the modules
          // of the current page. Otherwise the RichEditor component is
          // unmounted due to the newly loaded modules and the link ID ref is
          // lost which would make it impossible to set a link to a module on
          // the current page.
          getModules({ siteId, pageId, forceLoad: pageId !== currentPageId })
        }
        allowedLinkTypes={allowedLinkTypes}
      />
    </Sidebar>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { linkSelect, modules },
  { moduleId },
): StateProps => {
  const isHeaderModule = modules.byId[moduleId]?.type === "HeaderModule";

  return {
    linkSelect,
    // Allow only links to modules when in HeaderModule
    // (because of the voucher/specials) link selection,
    // otherwise allow all types.
    allowedLinkTypes: {
      external: !isHeaderModule,
      folders: !isHeaderModule,
      modules: true,
      pages: true,
    },
  };
};

const mapDispatchToProps = { resetLinkSelect, setLinkSelect, getModules };

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ModuleSettingsLinkSelect);
