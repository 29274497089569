import { FunctionComponent, useState } from "react";
import { ConnectedProps, MapStateToProps, connect } from "react-redux";
import {
  deleteNewsletterData,
  downloadNewsletterCSV,
} from "../actions/Newsletter.js";
import { getActiveSite } from "../selectors/sites.js";
import { StoreState } from "../types/index.js";
import ModalDialog from "./ModalDialog.js";

interface Props {}

type ReduxProps = ConnectedProps<typeof connector>;

interface StateProps {
  siteId: string;
}

const NewsletterDataExport: FunctionComponent<Props & ReduxProps> = ({
  downloadNewsletterCSV,
  deleteNewsletterData,
  siteId,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <section className="Sidebar__Section">
      <h3 className="Sidebar__Section__Title">Newsletter-Anmelde-Daten</h3>
      <p>
        Falls Sie ein Newsletter-Modul auf der Seite eingefügt haben, können Sie
        hier die gesammelten Adressen als CSV-Datei exportieren.
      </p>
      <button
        className="Btn"
        type="button"
        onClick={async () => {
          await downloadNewsletterCSV(siteId);
          setIsModalOpen(true);
        }}
      >
        CSV-Datei herunterladen
      </button>

      <ModalDialog
        isOpen={isModalOpen}
        type="confirm"
        title="Bisherige Daten löschen"
        onClose={() => setIsModalOpen(false)}
        actions={[
          {
            title: "Ja",
            onClick: () => {
              deleteNewsletterData(siteId);
              setIsModalOpen(false);
            },
          },
          { title: "Nein", onClick: () => setIsModalOpen(false) },
        ]}
      >
        Die Daten wurden exportiert und als CSV-Datei heruntergeladen. Möchten
        Sie die bisherigen Daten aus der Datenbank löschen?
      </ModalDialog>
    </section>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = ({
  sites,
}): StateProps => ({ siteId: getActiveSite(sites).id });

const mapDispatchToProps = { downloadNewsletterCSV, deleteNewsletterData };

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(NewsletterDataExport);
