import { FunctionComponent, useState } from "react";
import { getPictureWithSizedURL } from "../selectors/pictures.js";
import { ImageModuleIdsByPictureId, Picture } from "../types/index.js";
import FormInfo from "./FormInfo.js";
import Icon from "./Icon.js";
import MediaLibraryImage from "./MediaLibraryImage.js";
import Spinner from "./Spinner.js";

interface Props {
  onExpand?: () => {};
  groupIsLoaded: boolean;
  onSelection: (pictureId: string) => void;
  pictures: Picture[];
  selectedImages: ImageModuleIdsByPictureId;
  groupName: string;
}

const PictureGroupItem: FunctionComponent<Props> = ({
  onExpand,
  groupIsLoaded,
  onSelection,
  pictures,
  selectedImages,
  groupName,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <li className="MediaLibrary__PictureGroups__Group">
      <button
        className="Btn--bare MediaLibrary__PictureGroupsGroupButton"
        onClick={() => {
          !isExpanded && onExpand?.();
          setIsExpanded(!isExpanded);
        }}
      >
        <Icon
          className="MediaLibrary__ExpandIcon"
          glyph={isExpanded ? "arrow-up" : "arrow-down"}
        />
        {groupName}
      </button>

      {isExpanded && !groupIsLoaded && (
        <div className="MediaLibrary__PictureGroupsLoading">
          <Spinner />
        </div>
      )}

      {isExpanded && groupIsLoaded && (
        <div className="MediaLibrary__Images MediaLibrary__PictureGroups__GroupContent">
          {!!pictures.length ? (
            pictures
              .map(getPictureWithSizedURL({ width: 256, height: 128, mode: 1 }))
              .map(({ id, title, url }) => (
                <MediaLibraryImage
                  key={id}
                  url={url}
                  title={title}
                  isSelected={!!selectedImages[id]}
                  onSelection={() => onSelection(id)}
                  width={256}
                  height={128}
                />
              ))
          ) : (
            <FormInfo>
              Dieser Bildergruppe sind keine Bilder zugewiesen.
            </FormInfo>
          )}
        </div>
      )}
    </li>
  );
};

export default PictureGroupItem;
