import { FunctionComponent, useEffect, useRef, useState } from "react";
import { ConnectedProps, MapStateToProps, connect } from "react-redux";
import { Link } from "react-router-dom";
import { getPublishStatus, postPublish } from "../actions/Publish.js";
import {
  getActiveSite,
  getAliasDomains,
  getPrimaryDomain,
} from "../selectors/sites.js";
import { PostPublishStatus, StoreState, TaskStatus } from "../types/index.js";
import { getPublishedSiteURL, getURL } from "../utils/utils.js";
import Sidebar from "./Sidebar.js";
import Spinner from "./Spinner.js";

interface StateProps {
  domain: string;
  publishedSiteURL: URL;
  status: TaskStatus;
  siteId: string;
  aliasDomains: string[];
}

type ReduxProps = ConnectedProps<typeof connector>;

type AllProps = ReduxProps;

const PublishSettings: FunctionComponent<AllProps> = ({
  getPublishStatus,
  postPublish,
  siteId,
  status,
  domain,
  aliasDomains,
  publishedSiteURL,
}) => {
  const timeoutRef = useRef(0);
  const [isGenerating, setIsGenerating] = useState(false);
  const [postPublishStatus, setPostPublishStatus] =
    useState<PostPublishStatus>();

  useEffect(() => {
    getPublishStatus({ siteId, showLastResult: false });
  }, []);

  const handlePublish = async () => {
    setPostPublishStatus(undefined);
    const postPublishStatus = await postPublish(siteId);
    setPostPublishStatus(postPublishStatus);
  };

  const startPoll = () => {
    window.clearTimeout(timeoutRef.current);

    if (status !== "pending") return;

    timeoutRef.current = window.setTimeout(() => {
      getPublishStatus({ siteId, showLastResult: true });
      startPoll();
    }, 2000);
  };

  useEffect(() => {
    if (status !== "pending") {
      window.clearTimeout(timeoutRef.current);
      return;
    }

    !isGenerating && setIsGenerating(true);
    startPoll();
  }, [status, isGenerating]);

  const hasAliasDomains = !!aliasDomains.length;

  return (
    <Sidebar
      className="PublishSettings Sidebar--full"
      heading="Seite veröffentlichen"
    >
      {isGenerating && (
        <>
          {status === "pending" && (
            <div className="PublishSettings__Status">
              <Spinner />
              <h3>Bitte warten!</h3>
              <p>Die Seite wird generiert. Dieser Vorgang kann etwas dauern!</p>
            </div>
          )}
          {status === "successful" && (
            <div className="PublishSettings__Status">
              <svg
                className="SuccessAnimation SuccessAnimation--animated"
                xmlns="http://www.w3.org/2000/svg"
                width="70"
                height="70"
                viewBox="0 0 70 70"
              >
                <path
                  className="SuccessAnimation__Result"
                  fill="#ffffff"
                  d="M35,60 C21.1928813,60 10,48.8071187 10,35 C10,21.1928813 21.1928813,10 35,10 C48.8071187,10 60,21.1928813 60,35 C60,48.8071187 48.8071187,60 35,60 Z M23.6332378,33.2260427 L22.3667622,34.7739573 L34.1433655,44.40936 L47.776114,27.6305926 L46.223886,26.3694074 L33.8566345,41.59064 L23.6332378,33.2260427 Z"
                />
                <circle
                  className="SuccessAnimation__Circle"
                  cx="35"
                  cy="35"
                  r="24"
                  stroke="#ffffff"
                  strokeWidth="2"
                  strokeLinecap="round"
                  fill="transparent"
                />
                <polyline
                  className="SuccessAnimation__Check"
                  stroke="#ffffff"
                  strokeWidth="2"
                  points="23 34 34 43 47 27"
                  fill="transparent"
                />
              </svg>
              <h3>Herzlichen Glückwunsch!</h3>
              <p>Ihre Seite wurde erfolgreich veröffentlicht!</p>
            </div>
          )}
          {status === "failed" && (
            <div className="PublishSettings__Status">
              <img
                className="PublishSettings__Status__Image"
                src="/oh_no.png"
              />
              <h3>Oh nein!</h3>
              <p>
                Ein unerwarteter Fehler ist aufgetreten. Versuchen Sie es später
                noch einmal.
              </p>
            </div>
          )}
        </>
      )}
      <table className="PublishSettings__Summary Table">
        <tbody>
          <tr>
            <td>Hauptdomain</td>
            <td colSpan={2}>
              <a href={`https://${domain}`} target="_blank" rel="noopener">
                {domain}
              </a>
            </td>
          </tr>
          <tr>
            <td>Provisorische Domain</td>
            <td colSpan={2}>
              <a
                href={publishedSiteURL.toString()}
                target="_blank"
                rel="noopener"
              >
                {publishedSiteURL.host}
              </a>
            </td>
          </tr>
          {hasAliasDomains && (
            <tr>
              <td>Alternative Domains</td>
              <td>
                <ul className="List">
                  {aliasDomains.map((aliasDomain) => (
                    <li key={aliasDomain}>
                      <a
                        href={`https://${aliasDomain}`}
                        target="_blank"
                        rel="noopener"
                      >
                        {aliasDomain}
                      </a>
                    </li>
                  ))}
                </ul>
              </td>
              <td>leiten auf die Hauptdomain weiter</td>
            </tr>
          )}
        </tbody>
      </table>

      <button
        disabled={status === "pending"}
        className="PublishBtn Btn Btn--action"
        onClick={handlePublish}
      >
        Veröffentlichen
      </button>

      {postPublishStatus === "criticalIssues" && (
        <p>
          Auf der Seite wurden kritische Fehler gefunden, wodurch das
          Veröffentlichen der Seite nicht möglich ist. Wechseln Sie zur{" "}
          <Link to={getURL(siteId, "issues", "critical")}>Problembehebung</Link>{" "}
          und beheben Sie die kritischen Fehler.
        </p>
      )}
    </Sidebar>
  );
};

const mapStateToProps: MapStateToProps<StateProps, {}, StoreState> = ({
  sites,
  publish,
}): StateProps => {
  const site = getActiveSite(sites);

  return {
    domain: getPrimaryDomain(site),
    publishedSiteURL: getPublishedSiteURL(site.id),
    aliasDomains: getAliasDomains(site),
    status: publish.status,
    siteId: site.id,
  };
};

const mapDispatchToProps = { getPublishStatus, postPublish };

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(PublishSettings);
