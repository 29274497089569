import { forwardRef, HTMLAttributes, MouseEventHandler } from "react";
import { IconName } from "../types/index.js";
import Icon from "./Icon.js";

interface Props {
  icon: IconName;
  text: string;
  isSelected: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  buttonProps?: HTMLAttributes<HTMLButtonElement>;
}

const MockModule = forwardRef<HTMLButtonElement, Props>(
  ({ icon, text, isSelected, onClick, buttonProps }, ref) => (
    <button
      ref={ref}
      onClick={onClick}
      className="Btn--bare MockModule"
      {...buttonProps}
    >
      <figure className="MockModule__Inner">
        <Icon className="MockModule__Icon" glyph={icon} />
        {isSelected && (
          <Icon className="MockModule__SelectedIcon" glyph="check" />
        )}
        <figcaption>{text}</figcaption>
      </figure>
    </button>
  ),
);

export default MockModule;
