import { SeparatorIconName, SeparatorIconType } from "../types/index.js";

export const specialThemes = {
  1: "Wandern",
  2: "Rad & Mountainbike",
  3: "Familie",
  4: "Wellness / Gesundheit",
  5: "Essen und Trinken",
  6: "Golf",
  7: "Kultur",
  8: "Motorsport",
  9: "Ohne Auto im Urlaub",
  10: "Ski & Snowboard",
  11: "Sommer Aktiv",
  12: "Veranstaltungen",
  13: "Weihnachtsmärkte",
  14: "Winter Aktiv",
  // 72: "Tage der Architektur" is omitted intentionally
  79: "Ecologic Urlaub",
  80: "Reiten",
  81: "Luxuriöser Urlaub",
  82: "Haustier-freundlicher Urlaub",
  83: "Rennrad",
  84: "Romantischer Urlaub",
  85: "Wein",
  86: "Fahrradtouren",
  87: "eBike",
};

export const iconsById: { [id in SeparatorIconName]: SeparatorIconType } = {
  acorn: {
    author: "Petra Prgomet, The Noun Project",
    id: "acorn",
    title: "Haselnuss",
    url: "https://thenounproject.com/term/acorn/683858/",
  },
  "apple-1": {
    author: "Tatyana, The Noun Project",
    id: "apple-1",
    title: "Apfel",
    url: "https://thenounproject.com/term/apple/2412324/",
  },
  "apple-2": {
    author: "kimberly chandler, The Noun Project",
    id: "apple-2",
    title: "Apfel",
    url: "https://thenounproject.com/term/apple/76159/",
  },
  chestnut: {
    author: "Petra Prgomet, The Noun Project",
    id: "chestnut",
    title: "Kastanie",
    url: "https://thenounproject.com/term/chestnut/683861/",
  },
  cocktail: {
    author: "Tatyana, The Noun Project",
    id: "cocktail",
    title: "Cocktail",
    url: "https://thenounproject.com/term/cocktail/2530892/",
  },
  "coffee-1": {
    author: "Tatyana, The Noun Project",
    id: "coffee-1",
    title: "Kaffeebohne",
    url: "https://thenounproject.com/term/coffee/2663218/",
  },
  "coffee-2": {
    author: "Tatyana, The Noun Project",
    id: "coffee-2",
    title: "Kaffeetasse",
    url: "https://thenounproject.com/term/coffee/2230346/",
  },
  cow: {
    author: "Basti Steinhauer, The Noun Project",
    id: "cow",
    title: "Kuh",
    url: "https://thenounproject.com/term/cow/1637360/",
  },
  deer: {
    author: "Saeful Muslim, The Noun Project",
    id: "deer",
    title: "Hirsch",
    url: "https://thenounproject.com/term/deer/1427726/",
  },
  drink: {
    author: "Tomas Knopp, The Noun Project",
    id: "drink",
    title: "Getränk",
    url: "https://thenounproject.com/term/drink/450822/",
  },
  "flower-1": {
    author: "Dong Ik Seo, The Noun Project",
    id: "flower-1",
    title: "Blume",
    url: "https://thenounproject.com/term/flower/1762562/",
  },
  "flower-2": {
    author: "Dong Ik Seo, The Noun Project",
    id: "flower-2",
    title: "Blume",
    url: "https://thenounproject.com/term/flower/1762561/",
  },
  "leaf-1": {
    author: "Dong Ik Seo, The Noun Project",
    id: "leaf-1",
    title: "Blatt",
    url: "https://thenounproject.com/term/leaf/2471896/",
  },
  "leaf-2": {
    author: "Petra Prgomet, The Noun Project",
    id: "leaf-2",
    title: "Blatt",
    url: "https://thenounproject.com/term/leaf/1944397/",
  },
  "mountain-1": {
    author: "Jason Dilworth, The Noun Project",
    id: "mountain-1",
    title: "Berg",
    url: "https://thenounproject.com/term/mountain/1762564/",
  },
  "mountain-2": {
    author: "iconcheese, The Noun Project",
    id: "mountain-2",
    title: "Berg",
    url: "https://thenounproject.com/term/mountain/1627026/",
  },
  mushroom: {
    author: "Petra Prgomet, The Noun Project",
    id: "mushroom",
    title: "Pilz",
    url: "https://thenounproject.com/term/mushroom/683863/",
  },
  "oyster-mushroom": {
    author: "Petra Prgomet, The Noun Project",
    id: "oyster-mushroom",
    title: "Austernpilz",
    url: "https://thenounproject.com/term/oyster-mushroom/683859/",
  },
  pinecone: {
    author: "Svenja Lyon, The Noun Project",
    id: "pinecone",
    title: "Tannenzapfen",
    url: "https://thenounproject.com/term/pinecone/1961309/",
  },
  "plant-1": {
    author: "Tatyana, The Noun Project",
    id: "plant-1",
    title: "Pflanze",
    url: "https://thenounproject.com/term/plant/2594296/",
  },
  "plant-2": {
    author: "Petra Prgomet, The Noun Project",
    id: "plant-2",
    title: "Pflanze",
    url: "https://thenounproject.com/term/plant/683860/",
  },
  "sheep-1": {
    author: "Stevie Biffen, The Noun Project",
    id: "sheep-1",
    title: "Schaf",
    url: "https://thenounproject.com/term/sheep/1544225/",
  },
  "sheep-2": {
    author: "Olga, The Noun Project",
    id: "sheep-2",
    title: "Schaf",
    url: "https://thenounproject.com/term/sheep/1848824/",
  },
  "tree-1": {
    author: "Made by Made, The Noun Project",
    id: "tree-1",
    title: "Baum",
    url: "https://thenounproject.com/term/tree/1227594/",
  },
  "tree-2": {
    author: "Dong Ik Seo, The Noun Project",
    id: "tree-2",
    title: "Baum",
    url: "https://thenounproject.com/term/tree/1762558/",
  },
  "tree-3": {
    author: "Dong Ik Seo, The Noun Project",
    id: "tree-3",
    title: "Baum",
    url: "https://thenounproject.com/term/tree/2503548/",
  },
  wheel: {
    author: "ANA, The Noun Project",
    id: "wheel",
    title: "Rad",
    url: "https://thenounproject.com/term/wheel/124530/",
  },
  wine: {
    author: "Olyn LeRoy, The Noun Project",
    id: "wine",
    title: "Weinglas",
    url: "https://thenounproject.com/term/wine/91351/",
  },
  horse: {
    author: "Orin zuu, The Noun Project",
    id: "horse",
    title: "Pferd",
    url: "https://thenounproject.com/icon/horse-1368841/",
  },
  "hotel-diamant-diamond": {
    author: "Hotel Diamant",
    id: "hotel-diamant-diamond",
    title: "Diamant",
    url: "https://www.diamant.it/",
    accommodationId: 9934,
  },
  "spitalerhof-grape": {
    author: "Spitalerhof",
    id: "spitalerhof-grape",
    title: "Weintrauben",
    url: "https://www.spitalerhof.it/",
    accommodationId: 11506,
  },
  "widmann-heart": {
    author: "Widmann",
    id: "widmann-heart",
    title: "Herz",
    url: "https://www.pensionwidmann.com/",
    accommodationId: 11966,
  },
  "fodara-coca": {
    author: "Fodara Vedla",
    id: "fodara-coca",
    title: "Zirbenzapfen",
    url: "https://www.fodara.it/",
    accommodationId: 12869,
  },
  "dragon-fly": {
    author: "Unterhabsbergerhof",
    id: "dragon-fly",
    title: "Libelle",
    url: "https://www.unterhabsbergerhof.com/",
    accommodationId: 13124,
  },
  "flower-3": {
    author: "Hotel Winkler",
    id: "flower-3",
    title: "Blume",
    url: "https://www.hotel-winkler.it",
    accommodationId: 10387,
  },
  "winkler-heart": {
    author: "Hotel Winkler",
    id: "winkler-heart",
    title: "Herz",
    url: "https://www.hotel-winkler.it",
    accommodationId: 10387,
  },
  "villa-eva-mountain": {
    author: "Residence Villa Eva",
    id: "villa-eva-mountain",
    title: "Berg Silhouette",
    url: "https://www.villaeva.it/",
    accommodationId: 11915,
  },
  "hotel-theresia-tree": {
    author: "Hotel Theresia",
    id: "hotel-theresia-tree",
    title: "Baum",
    url: "https://www.theresia.it/",
    accommodationId: 11282,
  },
  "goste-milestone": {
    author: "Hotel Alte Goste",
    id: "goste-milestone",
    title: "Meilenstein",
    url: "https://www.alte-goste.com/",
    accommodationId: 12857,
  },
  "hotel-residence-egger-flower": {
    author: "Hotel Residence Egger",
    id: "hotel-residence-egger-flower",
    title: "Blume",
    url: "https://www.hotelegger.com/",
    accommodationId: 10473,
  },
  "pension-mitterhofer-heart": {
    author: "Pension Mitterhofer",
    id: "pension-mitterhofer-heart",
    title: "Herz",
    url: "https://www.pension-mitterhofer.com/",
    accommodationId: 11766,
  },
  "hotel-florian-mountain": {
    author: "Hotel Florian",
    id: "hotel-florian-mountain",
    title: "Berg",
    url: "https://www.hotel-florian.info/",
    accommodationId: 10422,
  },
  "hotel-florian-deer": {
    author: "Hotel Florian",
    id: "hotel-florian-deer",
    title: "Hirsch",
    url: "https://www.hotel-florian.info/",
    accommodationId: 10422,
  },
  "residence-villa-calluna-blossoms": {
    author: "Residence Villa Calluna",
    id: "residence-villa-calluna-blossoms",
    title: "Blüten",
    url: "https://www.villacalluna.com/",
    accommodationId: 12180,
  },
  "villa-waldheim-tree": {
    author: "Hotel Villa Waldheim",
    id: "villa-waldheim-tree",
    title: "Baum",
    url: "https://www.villawaldheim.it/",
    accommodationId: 11741,
  },
  "villa-waldheim-trees": {
    author: "Hotel Villa Waldheim",
    id: "villa-waldheim-trees",
    title: "Bäume",
    url: "https://www.villawaldheim.it/",
    accommodationId: 11741,
  },
  "alpina-mountain": {
    author: "Residence Alpina",
    id: "alpina-mountain",
    title: "Berg",
    url: "https://www.residence-alpina.com/",
    accommodationId: 12675,
  },
  "goldener-adler-eagle": {
    author: "Goldener Adler",
    id: "goldener-adler-eagle",
    title: "Adler",
    url: "https://www.goldeneradler.it/",
    accommodationId: 10351,
  },
  "waldhof-stones": {
    author: "Hotel Waldhof",
    id: "waldhof-stones",
    title: "Steine",
    url: "https://www.hotelwaldhof.it/",
    accommodationId: 10905,
  },
  "alpenspitz-icon": {
    author: "Hotel Alpenspitz",
    id: "alpenspitz-icon",
    title: "Icon",
    url: "https://www.alpenspitz.com/",
    accommodationId: 13180,
  },
  "garni-faerbe-icon": {
    author: "Garni Färbe",
    id: "garni-faerbe-icon",
    title: "Icon",
    url: "https://www.garni-faerbe.com/",
    accommodationId: 12852,
  },
  "garni-faerbe-small": {
    author: "Garni Färbe",
    id: "garni-faerbe-small",
    title: "Icon",
    url: "https://www.garni-faerbe.com/",
    accommodationId: 12852,
  },
  "balls-waldhof": {
    author: "Vitalpina Hotel Waldhof",
    id: "balls-waldhof",
    title: "Kugeln",
    url: "https://www.hotelwaldhof.it/",
    accommodationId: 10905,
  },
  "roesslwirt-tower": {
    author: "Rösslwirt",
    id: "roesslwirt-tower",
    title: "Turm",
    url: "https://www.roesslwirt.com/",
    accommodationId: 10213,
  },
  "penegal-mountains": {
    author: "Panoramahotel Penegal",
    id: "penegal-mountains",
    title: "Berg",
    url: "https://www.penegal.it/",
    accommodationId: 13312,
  },
  "unterwirt-mountain": {
    author: "Gasthof Unterwirt",
    id: "unterwirt-mountain",
    title: "Berg",
    url: "https://www.gasthofunterwirt.com/",
    accommodationId: 13415,
  },
  "villa-calluna-calluna": {
    author: "Villa Calluna",
    id: "villa-calluna-calluna",
    title: "Pflanze",
    url: "https://www.villacalluna.com/",
    accommodationId: 12180,
  },
  "mountain-langeshof": {
    author: "Hotel Langeshof",
    id: "mountain-langeshof",
    title: "Berg",
    url: "https://www.langeshof.com/",
    accommodationId: 10500,
  },
  "margit-flower": {
    author: "Appartements Margit",
    id: "margit-flower",
    title: "Blume",
    url: "https://www.app-margit.com/",
    accommodationId: 11472,
  },
  "milla-flower1": {
    author: "Milla Apartments",
    id: "milla-flower1",
    title: "Blume 1",
    url: "https://www.naturhotel.com/",
    accommodationId: 13467,
  },
  "milla-flower2": {
    author: "Milla Apartments",
    id: "milla-flower2",
    title: "Blume 2",
    url: "https://www.naturhotel.com/",
    accommodationId: 13467,
  },
  "milla-flower3": {
    author: "Milla Apartments",
    id: "milla-flower3",
    title: "Blume 3",
    url: "https://www.naturhotel.com/",
    accommodationId: 13467,
  },
  "milla-flower4": {
    author: "Milla Apartments",
    id: "milla-flower4",
    title: "Blume 4",
    url: "https://www.naturhotel.com/",
    accommodationId: 13467,
  },
  "milla-flower5": {
    author: "Milla Apartments",
    id: "milla-flower5",
    title: "Blume 5",
    url: "https://www.naturhotel.com/",
    accommodationId: 13467,
  },
  "chalet-luis-flower": {
    author: "Luis Chalet",
    id: "chalet-luis-flower",
    title: "Blume",
    url: "https://www.luis-chalet.com/",
    accommodationId: 13499,
  },
  "eppanerhof-star": {
    author: "Residence Eppanerhof",
    id: "eppanerhof-star",
    title: "Stern",
    url: "https://www.eppanerhof.com/",
    accommodationId: 10815,
  },
  "hotel-nachtigall-bird": {
    author: "Garni-Hotel Nachtigall",
    id: "hotel-nachtigall-bird",
    title: "Vogel",
    url: "https://www.nachtigall.it/",
    accommodationId: 10658,
  },
  "pension-eisendle-leaves": {
    author: "Pension Eisendle",
    id: "pension-eisendle-leaves",
    title: "Blätter",
    url: "https://www.pension-eisendle.com/",
    accommodationId: 11555,
  },
  "blitzburg-house": {
    author: "Blitzburg",
    id: "blitzburg-house",
    title: "Haus",
    url: "https://www.blitzburg.it/",
    accommodationId: 10789,
  },
  "hubertus-antler": {
    author: "Villa Hubertus",
    id: "hubertus-antler",
    title: "Geweih",
    url: "https://www.villahubertus.it/",
    accommodationId: 13529,
  },
  "margherita-separator-icon-1": {
    author: "Apartments Margherita",
    id: "margherita-separator-icon-1",
    title: "Icon 1",
    url: "https://www.apartmentsmargherita.it/",
    accommodationId: 13572,
  },
  "margherita-separator-icon-2": {
    author: "Apartments Margherita",
    id: "margherita-separator-icon-2",
    title: "Icon 2",
    url: "https://www.apartmentsmargherita.it/",
    accommodationId: 13572,
  },
  "margherita-separator-icon-3": {
    author: "Apartments Margherita",
    id: "margherita-separator-icon-3",
    title: "Icon 3",
    url: "https://www.apartmentsmargherita.it/",
    accommodationId: 13572,
  },
  "residence-neuhaeuslhof-pattern": {
    author: "Residence Neuhäuslhof",
    id: "residence-neuhaeuslhof-pattern",
    title: "Muster",
    url: "https://www.neuhaeuslhof.com/",
    accommodationId: 10567,
  },
};

export const legalForms = <const>[
  "Einzelunternehmen",
  "Einfache Gesellschaft",
  "Offene Handelsgesellschaft",
  "Einfache Kommanditgesellschaft",
  "Gesellschaft mit beschränkter Haftung",
  "Ein-Personen-Gesellschaft",
  "Aktiengesellschaft",
  "Ein-Personen-Aktiengesellschaft",
  "Kommanditgesellschaft auf Aktien",
  "Genossenschaft",
];
