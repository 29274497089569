import ClassNames from "classnames";
import { FunctionComponent } from "react";
import { style } from "typestyle";
import i18n from "../i18n/index.js";
import {
  ColorScheme,
  Language,
  LoadStatus,
  SeparatorIconName,
} from "../types/index.js";
import { iconsById } from "../utils/data.js";
import { getTranslations } from "../utils/utils.js";
import Icon from "./Icon.js";
import SeparatorIcon from "./SeparatorIcon.js";
import ToggleShowBox from "./ToggleShowBox.js";

interface Props {
  iconIds: SeparatorIconName[];
  onToggleOpen: () => void;
  loadStatus: LoadStatus;
  scheme: ColorScheme;
  languageId: Language;
}

const IconsCopyright: FunctionComponent<Props> = ({
  iconIds,
  onToggleOpen,
  loadStatus,
  scheme,
  languageId,
}) => {
  const hasNounProjectIcons = Boolean(
    loadStatus === "loaded" && iconIds.length,
  );
  const trans = getTranslations(languageId, i18n).imprint;

  const linkStyleClass = style({ color: scheme.main.title });

  return (
    <ToggleShowBox
      className="IconsCopyright"
      title={trans.icons}
      onToggleOpen={onToggleOpen}
      loadStatus={loadStatus}
      languageId={languageId}
    >
      <ul className="List">
        <li className="IconsCopyright__Item">
          <a
            className="IconsCopyright__ItemInner"
            href="https://fonts.google.com/icons"
            target="_blank"
            rel="noopener"
          >
            <Icon className="IconsCopyright__Icon" glyph="material-design" />
            <span
              className={ClassNames("IconsCopyright__Caption", linkStyleClass)}
            >
              Material icons
            </span>
          </a>
        </li>

        {hasNounProjectIcons &&
          iconIds.map((id) => {
            const icon = iconsById[id];
            if (!icon) return null;

            return (
              <li className="IconsCopyright__Item" key={id}>
                <a
                  className="IconsCopyright__ItemInner"
                  href={icon.url}
                  target="_blank"
                  rel="noopener"
                >
                  <SeparatorIcon className="IconsCopyright__Icon" glyph={id} />
                  <span
                    className={ClassNames(
                      "IconsCopyright__Caption",
                      linkStyleClass,
                    )}
                  >
                    {trans.author}: {icon.author}
                  </span>
                </a>
              </li>
            );
          })}
      </ul>
    </ToggleShowBox>
  );
};

export default IconsCopyright;
