import ClassNames from "classnames";
import { HTMLProps, MouseEventHandler, ReactNode, forwardRef } from "react";
import { ModuleSortInsertPosition, ModuleType } from "../types/index.js";
import Icon from "./Icon.js";

interface Props {
  id: string;
  isActive: boolean;
  onSelect: (id: string) => void;
  isUntranslated: boolean;
  moduleType: ModuleType;
  divProps?: HTMLProps<HTMLDivElement>;
  children: ReactNode;
  isDragging: boolean;
  insertPosition: ModuleSortInsertPosition | undefined;
}

const ModuleWrapper = forwardRef<HTMLDivElement, Props>(
  (
    {
      id,
      isActive,
      isUntranslated,
      onSelect,
      moduleType,
      children,
      divProps,
      isDragging,
      insertPosition,
    },
    ref,
  ) => {
    const onClick: MouseEventHandler = (event) => {
      if (!isActive) {
        // Prevent following links if the module is not active
        event.preventDefault();
      }

      onSelect(id);
    };

    return (
      <div
        className={ClassNames("ModuleWrapper", `ModuleWrapper--${moduleType}`, {
          "ModuleWrapper--active": isActive,
          "ModuleWrapper--dragging": isDragging,
          "ModuleWrapper--untranslated": isUntranslated,
          "ModuleWrapper--insert-before":
            insertPosition === ModuleSortInsertPosition.Before,
          "ModuleWrapper--insert-after":
            insertPosition === ModuleSortInsertPosition.After,
        })}
        onClick={isUntranslated ? undefined : onClick}
        ref={ref}
        {...divProps}
      >
        {isUntranslated && (
          <>
            <div className="ModuleWrapper__Untranslated">{children}</div>
            <button
              type="button"
              className="Btn ModuleWrapper__Translate"
              onClick={onClick}
            >
              <Icon glyph="translate" /> Übersetzen
            </button>
          </>
        )}
        {!isUntranslated && children}
      </div>
    );
  },
);

export default ModuleWrapper;
