import { FunctionComponent, useEffect, useRef, useState } from "react";
import { ConnectedProps, MapStateToProps, connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { IssuesChecker } from "../../server/types/index.js";
import {
  getIssues,
  getIssuesCheck,
  patchIssue,
  postIssuesCheck,
} from "../actions/Issues.js";
import {
  IssueItemType,
  IssueSeverity,
  IssueType,
  LoadStates,
  StoreState,
  ThunkDispatch,
} from "../types/index.js";
import { getURL, keys, parseISO } from "../utils/utils.js";
import FormInfo from "./FormInfo.js";
import Icon from "./Icon.js";
import IssuesDashboard from "./IssuesDashboard.js";
import IssuesTable from "./IssuesTable.js";
import Sidebar from "./Sidebar.js";
import Spinner from "./Spinner.js";
import Tab from "./Tab.js";

type UrlType = "critical" | "errors" | "warnings" | "archive" | "resolved";

type Props = RouteComponentProps<{ siteId: string; urlType: UrlType }>;

type ItemsByType = { [type in IssueType]?: IssueItemType[] };

interface StateProps {
  loadStates: LoadStates["issues"];
  checker: IssuesChecker;
  itemsByType: ItemsByType;
  hasIssues: boolean;
}

type ReduxProps = ConnectedProps<typeof connector>;

const emptyMessages: { [type in UrlType]: string } = {
  critical: "Es gibt keine kritischen Fehler auf der Seite.",
  errors: "Es gibt keine Fehler auf der Seite.",
  warnings: "Es gibt keine Warnungen auf der Seite.",
  archive: "Es gibt keine archivierten Fehler / Warnungen.",
  resolved: "Es gibt keine behobenen Fehler / Warnungen.",
};

const Issues: FunctionComponent<Props & ReduxProps> = ({
  match: {
    params: { siteId, urlType },
  },
  getIssues,
  patchIssue,
  postIssuesCheck,
  getIssuesCheck,
  itemsByType,
  hasIssues: hasIssues,
  loadStates,
  checker,
}) => {
  // Use a ref to access the current value in an async callback.
  const isPendingRef = useRef(false);
  const isPending = checker.status === "pending";
  isPendingRef.current = isPending;
  const urlTypeRef = useRef(urlType);
  urlTypeRef.current = urlType;

  const startPoll = () => {
    pollTimeout.current = window.setTimeout(() => {
      getIssuesCheck(siteId, true);
      getIssues(siteId, urlTypeRef.current);
      if (isPendingRef.current) startPoll();
    }, 5000);
  };

  const [openStates, setOpenStates] = useState<{
    [type in IssueType]?: boolean;
  }>({});

  const pollTimeout = useRef<number>();

  useEffect(() => {
    getIssues(siteId, urlType);
  }, [urlType]);

  useEffect(() => {
    getIssuesCheck(siteId, false);
    // cleanup
    return () => window.clearTimeout(pollTimeout.current);
  }, []);

  useEffect(() => {
    isPending && startPoll();
  }, [isPending]);

  const issuesUrl = getURL(siteId, "issues");

  return (
    <Sidebar className="Issues Sidebar--full" heading="Problembehebung">
      <FormInfo>
        Starten Sie eine neue Überprüfung, um eine aktuelle Liste aller Fehler
        und Warnungen der Seite zu erhalten und diese zu bereinigen.
      </FormInfo>

      <IssuesDashboard
        checkedAt={checker.lastRun === null ? null : parseISO(checker.lastRun)}
        currentStatus={checker.status}
        lastCheckCaption="Letzte Problemüberprüfung"
        noIssuesMessage={"Super! Es gibt keine Fehler auf der Seite."}
        onStartClick={() => postIssuesCheck(siteId)}
        issuesCount={checker.issues}
        solveIssuesMessage={
          "Klicken Sie auf den Referenz-Link, um Fehler " +
          "und Warnungen zu beheben. " +
          "Durch „Archivieren“ werden Fehler / Warnungen " +
          "ignoriert und  aus der Liste entfernt."
        }
        entitiesTerm="Fehler / Warnungen"
      />

      <div className="IssuesList">
        <ul className="Tabs">
          <Tab
            icon="error"
            isActive={urlType === "critical"}
            to={getURL(issuesUrl, "critical")}
          >
            Kritisch ({checker.critical})
          </Tab>
          <Tab
            icon="error"
            isActive={urlType === "errors"}
            to={getURL(issuesUrl, "errors")}
          >
            Fehler ({checker.errors})
          </Tab>
          <Tab
            icon="warning"
            isActive={urlType === "warnings"}
            to={getURL(issuesUrl, "warnings")}
          >
            Warnungen ({checker.warnings})
          </Tab>
          <Tab
            icon="archive"
            isActive={urlType === "archive"}
            to={getURL(issuesUrl, "archive")}
          >
            Archiviert ({checker.archived})
          </Tab>
          <Tab
            icon="check"
            isActive={urlType === "resolved"}
            to={getURL(issuesUrl, "resolved")}
          >
            Behoben ({checker.resolved})
          </Tab>
        </ul>
        <div className="TableWrapper">
          {hasIssues && (
            <ul className="List">
              {keys(itemsByType).map((type) => {
                const byCurrentType = itemsByType[type];
                if (!byCurrentType) return null;
                const isOpen = openStates[type] || false;
                return (
                  <li className="IssuesList__Category" key={type}>
                    <button
                      className="Btn--bare IssueItem__CategoryButton"
                      onClick={() => {
                        setOpenStates({ ...openStates, [type]: !isOpen });
                      }}
                    >
                      <Icon
                        className="IssueItem__ExpandIcon"
                        glyph={isOpen ? "arrow-up" : "arrow-down"}
                      />
                      {byCurrentType[0].description} ({byCurrentType.length})
                    </button>

                    {isOpen && (
                      <IssuesTable
                        showsResolvedIssues={urlType === "resolved"}
                        items={byCurrentType}
                        onArchive={(id, archive) =>
                          patchIssue(siteId, id, archive)
                        }
                      />
                    )}
                  </li>
                );
              })}
            </ul>
          )}
          {loadStates.issues === "loading" && !hasIssues && (
            <div>
              <Spinner />
            </div>
          )}
          {loadStates.issues === "loaded" && !hasIssues && (
            <div className="IssuesList__Empty">{emptyMessages[urlType]}</div>
          )}
        </div>
      </div>
    </Sidebar>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = ({
  issues: {
    checker,
    issues: { byId, byType, allIds },
  },
  loadStates,
}) => {
  const itemsByType = keys(byType).reduce<ItemsByType>((acc, type) => {
    acc[type] = (byType[type] || []).map((id) => byId[id]);
    return acc;
  }, {});

  return {
    itemsByType,
    loadStates: loadStates.issues,
    checker,
    hasIssues: !!allIds.length,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  getIssues: (siteId: string, urlType: UrlType) => {
    const mapping: { [urlType in UrlType]: IssueSeverity | undefined } = {
      errors: "error",
      critical: "critical",
      warnings: "warning",
      archive: undefined,
      resolved: undefined,
    };

    dispatch(
      getIssues(
        siteId,
        {
          archived: urlType === "resolved" ? undefined : urlType === "archive",
          severity: mapping[urlType],
          resolved: urlType === "resolved",
        },
        false,
      ),
    );
  },
  patchIssue: (siteId: string, issueId: string, archive: boolean) =>
    dispatch(patchIssue(siteId, issueId, archive)),
  postIssuesCheck: (siteId: string) => dispatch(postIssuesCheck(siteId)),
  getIssuesCheck: (siteId: string, showLastResult: boolean) =>
    dispatch(getIssuesCheck({ siteId, forceUpdate: true, showLastResult })),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Issues);
