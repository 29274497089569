import { FunctionComponent, useState } from "react";
import { IssueItemType } from "../types/index.js";
import IssueItem from "./IssueItem.js";

interface Props {
  items: IssueItemType[];
  onArchive: (id: string, archive: boolean) => void;
  showsResolvedIssues: boolean;
}

const IssuesTable: FunctionComponent<Props> = ({
  items,
  onArchive,
  showsResolvedIssues,
}) => {
  const [itemsShown, setItemsShown] = useState(20);
  const showMoreButton = itemsShown < items.length;

  return (
    <>
      <table className="Table">
        <thead>
          <tr>
            <th></th>
            <th>Typ</th>
            <th>Referenz</th>
            <th>Problem gefunden am</th>
            <th>{showsResolvedIssues && "Problem behoben am"}</th>
          </tr>
        </thead>
        <tbody>
          {items
            .slice(0, itemsShown)
            .map(
              ({
                id,
                firstSeen,
                isArchived,
                link,
                type,
                description,
                severity,
                title,
                resolvedAt,
              }) => (
                <IssueItem
                  key={id}
                  firstSeen={firstSeen}
                  id={id}
                  isArchived={isArchived}
                  link={link}
                  onArchive={onArchive}
                  type={type}
                  description={description}
                  severity={severity}
                  title={title}
                  resolvedAt={resolvedAt}
                  onLinkClick={undefined}
                />
              ),
            )}
        </tbody>
      </table>
      {showMoreButton && (
        <div className="BtnGroup BtnGroup--full-width">
          <button
            type="button"
            className="Btn Btn--action"
            onClick={() => setItemsShown(itemsShown + 20)}
          >
            weitere anzeigen
          </button>
        </div>
      )}
    </>
  );
};

export default IssuesTable;
