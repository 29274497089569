import ClassNames from "classnames";
import { FunctionComponent, ReactNode, useState } from "react";
import { getHtmlFieldId } from "../utils/utils.js";

interface Props {
  value: string | undefined;
  label: string;
  disabled?: boolean;
  onChange: (value: string) => void;
  validation?: { errorMessage: string; validate: (value: string) => boolean };
  placeholder?: string;
  maxLength?: number;
  children?: ReactNode;
}

const TextFormField: FunctionComponent<Props> = ({
  value,
  label,
  disabled,
  onChange,
  placeholder,
  validation,
  maxLength,
  children,
}) => {
  const [{ formValue, isValid }, setState] = useState<{
    formValue: string;
    isValid: boolean;
  }>({
    formValue: value ?? "",
    isValid: validation?.validate(value ?? "") ?? true,
  });

  const htmlId = getHtmlFieldId(label);

  return (
    <div
      className={ClassNames("Form__Field", {
        "Form__Field--disabled": disabled,
      })}
    >
      <input
        maxLength={maxLength}
        id={htmlId}
        type="text"
        disabled={disabled}
        value={formValue}
        placeholder={placeholder}
        onChange={(event) => {
          const currentValue = event.target.value;
          const newIsValid = validation?.validate(currentValue) ?? true;
          setState({ formValue: currentValue, isValid: newIsValid });
          newIsValid && onChange(currentValue);
        }}
      />
      {children}
      <div className="Form__Label">
        <label htmlFor={htmlId}>{label}</label>
      </div>
      {!isValid && validation && (
        <div className="Form__Error">{validation.errorMessage}</div>
      )}
    </div>
  );
};

export default TextFormField;
