import { FunctionComponent } from "react";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import { useHistory, useParams } from "react-router";
import {
  Language,
  ModulesBySiteModuleType,
  ModuleType,
  OnModuleAdd,
  StoreState,
} from "../types/index.js";
import {
  getModuleIcon,
  getModuleTypeTranslation,
  getShortId,
  getURL,
} from "../utils/utils.js";
import MockModule from "./MockModule.js";

interface Props {
  onAdd: OnModuleAdd;
}

interface StateProps {
  bySiteModuleType: ModulesBySiteModuleType;
}

type ReduxProps = ConnectedProps<typeof connector>;

const GlobalModulesList: FunctionComponent<Props & ReduxProps> = ({
  bySiteModuleType,
  onAdd,
}) => {
  const history = useHistory();
  const { siteId, pageId, languageId } = useParams<{
    siteId: string;
    pageId: string;
    languageId: Language;
  }>();

  const onClick = (
    existingModuleId: string | undefined,
    moduleType: ModuleType,
  ) => {
    const moduleId = existingModuleId ?? getShortId();

    existingModuleId === undefined &&
      onAdd({ moduleType, moduleId, usePageId: false });

    history.push(
      getURL(siteId, "pages", pageId, languageId, "modules", moduleId),
    );
  };

  const moduleTypes: ModuleType[] = ["QuickEnquiryModule"];

  return (
    <>
      <div className="Sidebar__Headings">
        <h2 className="Sidebar__Heading">Globale Module</h2>
      </div>
      <div className="ModuleList">
        {moduleTypes.map((moduleType) => {
          const existingModuleId = bySiteModuleType[moduleType]?.[0];
          return (
            <MockModule
              key={moduleType}
              icon={getModuleIcon(moduleType)}
              text={getModuleTypeTranslation(moduleType)}
              isSelected={existingModuleId !== undefined}
              onClick={() => onClick(existingModuleId, moduleType)}
            />
          );
        })}
      </div>
    </>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = ({
  modules: { bySiteModuleType },
}): StateProps => ({ bySiteModuleType });

const connector = connect(mapStateToProps);

export default connector(GlobalModulesList);
